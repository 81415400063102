<template>
  <div v-if="!showData" style="margin: auto;padding: 100px;">
    <v-btn @click="() => { showData = true }" color="primary" block x-large size="x-large">Mostrar pedidos</v-btn>
  </div>
  <div v-else-if="dtouchWorkspace">
    <dish-title 
      title="dtouch.dishOrders.title"
      :locale="locale"
      :isMobile="isMobile"
    >
      <template #caption>
        <v-layout
          v-if="isMobile"
          wrap
        >
          <v-flex xs5 style="padding: 10px 5px;">
            <v-select
              v-if="filterStatus"
              v-model="selectedFilterStatus"
              :items="filterStatus"
              item-value="ID"
              item-text="Name"
              outlined
              dense
              :label="$t('dtouch.dishOrders.status', locale)"
              hide-details
              multiple
              chips
              small-chips
            />
          </v-flex>
          <v-flex xs6 style="padding: 10px 5px;">
            <v-select
              v-if="filterPoints"
              v-model="selectedFilterPoint"
              :items="filterPoints"
              item-value="ID"
              item-text="Name"
              outlined
              dense
              :label="$t('dtouch.dishOrders.points.title', locale)"
              hide-details
            />
          </v-flex>
          <v-flex xs1 style="padding: 10px 5px;">
            <v-btn icon @click="handleForceUpdate">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout
          v-else
          wrap
        >
          <v-flex xs4 style="padding: 10px 5px;">
            <v-autocomplete
              v-if="filterStatus"
              v-model="selectedFilterStatus"
              :items="filterStatus"
              item-value="ID"
              item-text="Name"
              outlined
              dense
              :label="$t('dtouch.dishOrders.status', locale)"
              hide-details
              multiple
              chips
              small-chips
            />
          </v-flex>

          <v-flex xs3 style="padding: 10px 5px;">
            <v-autocomplete
              v-if="filterPoints"
              v-model="selectedFilterPoint"
              :items="filterPoints"
              item-value="ID"
              item-text="Name"
              outlined
              dense
              :label="$t('dtouch.dishOrders.points.title', locale)"
              hide-details
            />
          </v-flex>
          <v-flex xs2 style="padding-top: 10px;">
            <v-btn @click="handleForceUpdate">
              <v-icon>mdi-refresh</v-icon> ({{secondsToRefresh}})
            </v-btn>
          </v-flex>
          <v-flex xs2 style="padding-top: 10px;">
            <v-btn v-if="!hideOrderPoint" @click="handleGoTo" color="primary">
              <!--v-icon>mdi-book-marker</v-icon-->
              <v-icon>mdi-cog</v-icon>
              Puntos de pedido
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
    </dish-title>
    <v-layout
      wrap
    >
      <v-flex xs12>
        <orders-list
          :forceUpdate="forceUpdate"
          :locale="locale"
          :selectedPoint="selectedFilterPoint"
          :selectedStatus="selectedFilterStatus"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiDishOrders from '@/services/apiDishOrders'
import utils from '@/services/utils'
import DishTitle from './Title'
import OrdersList from './ordersList/Index'
export default {
  components: {
    DishTitle,
    OrdersList,
  },
  data: () => ({
    showData: false,
    timer: null,
    secondsToRefresh: 60,
    hideOrderPoint: true,
    forceUpdate: null,
    filterPoints: null,
    selectedFilterPoint: null,
    filterStatus: [
      {
        ID: 'INI',
        Key: 'INI',
        Name: 'initial',
      },
      {
        ID: 'confirmed',
        Key: 'confirmed',
        Name: 'confirmed',
      },
      {
        ID: 'finished',
        Key: 'finished',
        Name: 'finished',
      },
    ],
    selectedFilterStatus: ['confirmed'],
  }),
  computed: {
    ...mapState('app',['locale', 'dtouchWorkspace']),
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    isMobile () {
      return utils.isMobile()
    },
  },
  watch: {
    locale () {
      this.prepareFilterStatus ()
    },
    dtouchWorkspace () {
      this.handleGetUserPermission()
      this.handleGetPoints()
    },
  },
  mounted () {
    this.timer = setInterval(() => {
      this.secondsToRefresh = this.secondsToRefresh - 1
      if(this.secondsToRefresh === 0) {
        this.handleForceUpdate()
        this.secondsToRefresh = 60
      }
    }, 1000)
    this.handleGetUserPermission()
    this.prepareFilterStatus()
    this.handleGetPoints()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async handleGetUserPermission () {
      this.hideOrderPoint = await apiDishOrders.getUserPermission() 
    },
    prepareFilterStatus () {
      this.filterStatus = this.filterStatus.map(item => {
        item.Name = this.$t(`dtouch.dishOrders.statusValues.${item.Key}`, this.locale)
        return item
      })
    },
    handleGetPoints () {
      if(!this.dtouchWorkspace) return
      apiDishOrders.getAllPoints(0, 1000) 
        .then((response) => {
          this.filterPoints = [{ ID: null, Name: this.$t('dtouch.dishOrders.all', this.locale)}, ...response.data.map(item => { return { ID: item.ID, Name: item.Name } })]
        })
    },
    handleForceUpdate () {
      this.forceUpdate = new Date()
    },
    handleGoTo () {
      this.$router.push('./dish-orders-points')
    },
  },
}
</script>

